<template>
    <div>
        <div class="form-row" v-if="!floorplansList.length">
            <div class="bg-red-100 p-4 text-xs w-full text-red-500">
                <icon name="info" class="h-6 w-6 inline mr-4"></icon>You do not have any floorplan added yet.
            </div>
        </div>

        <div class="form-row">
            <div class="form-col">
                <label for="section_title">section title</label>
                <text-input
                  id="section_title"
                  v-model="floorplans.sectionTitle"
                />
                <span v-if="hasError('floorplans.sectionTitle')" class="form-error">{{ showError('floorplans.sectionTitle') }}</span>

                <label for="description" class="mt-8">description</label>
                <text-input
                  id="description"
                  v-model="floorplans.description"
                  multiline
                />
                <span v-if="hasError('floorplans.description')" class="form-error">{{ showError('floorplans.description') }}</span>

                <label for="buttonText" class="mt-8">button text</label>
                <text-input
                  id="buttonText"
                  v-model="floorplans.buttonText"
                />
                <label class="mt-8">button link</label>
                <url-input v-model="floorplans.buttonLink" :website-id="websiteId"></url-input>
            </div>
            <div class="form-col">
                <label>show filter</label>
                <span class="form-hint">Adds a filter bar to the module that lets the user filter the list of floor plans by certain criteria.</span>
                <toggle-input v-model="floorplans.showFilter" class="mt-3" />

                <label class="mt-8">floor plan features to display in overview</label>
                <p class="form-hint">These features will be shown to the user when clicking on a floor plan preview. Check all that apply.</p>

                <div class="features mt-4">
                    <div>
                        <input type="checkbox"  id="unit_type" class="mr-1" v-model="floorplans.showUnitType" />
                        <label for="unit_type" class="text-sm">Unit types</label>
                    </div>
                    <div>
                        <input type="checkbox"  id="showBeds" class="mr-1" v-model="floorplans.showBeds" />
                        <label for="showBeds" class="text-sm">beds/baths</label>
                    </div>
                    <div>
                        <input type="checkbox"  id="showSqft" class="mr-1" v-model="floorplans.showSqft" />
                        <label for="showSqft" class="text-sm">square feet</label>
                    </div>
                    <div>
                        <input type="checkbox"  id="showAvailability" class="mr-1" v-model="floorplans.showAvailability" />
                        <label for="showAvailability" class="text-sm">availability</label>
                    </div>
                    <div>
                        <input type="checkbox"  id="showPrice" class="mr-1" v-model="floorplans.showPrice" />
                        <label for="showPrice" class="text-sm">price</label>
                    </div>
                    <div>
                        <input type="checkbox"  id="showDescription" class="mr-1" v-model="floorplans.showDescription" />
                        <label for="showDescription" class="text-sm">description</label>
                    </div>
                    <div>
                        <input type="checkbox"  id="callsToAction" class="mr-1" v-model="floorplans.callsToAction" />
                        <label for="callsToAction" class="text-sm">calls to action (schedule/virtual tour links)</label>
                    </div>
                </div>
            </div>
        </div>
       <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Widget from "@/mixins/Widget";
    import Icon from "@/components/ui/Icon";
    import ToggleInput from "@/components/ui/ToggleInput";
    import UrlInput from "@/components/cms/UrlInput";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import TextInput from '@/components/ui/TextInput';

    export default {
        mixins: [ ModalNavigation, Widget ],
        components: { ModalFooter, ToggleInput, UrlInput, Icon, TextInput },
        data: () => {
            return {
                floorplans:  {
                    sectionTitle: null,
                    description: null,
                    buttonText: null,
                    buttonLink: null,
                    showFilter: false,
                    showUnitType: false,
                    showBeds: false,
                    showSqft: false,
                    showAvailability: false,
                    showPrice: false,
                    showDescription: false,
                    callsToAction: false
                },
                websiteId: null,
                isProcessing: false,
            }
        },
        emits: ['save-page-widget'],
        computed: {
            ...mapGetters({
                floorplansList: 'floorplans/getFloorplans'
            }),
        },
        methods: {
            save() {
                if(this.validator.passes()) {
                    this.isProcessing = true;
                    this.$emit('save-page-widget', Object.assign({}, this.floorplans));
                }
            },
            parseBoolean(value) {
                return ['1', 'true', true].includes(value);
            }
        },
        validations: {
            'floorplans.sectionTitle' : 'required',
            'floorplans.description' : 'required',
        },
        created() {
            this.initValidator();
            this.websiteId = this.$route.params.websiteId;

            if(this.values.length) {
                Object.assign(this.floorplans, {
                    sectionTitle: this.getValue('sectionTitle'),
                    description: this.getValue('description'),
                    buttonText: this.getValue('buttonText'),
                    buttonLink: this.getValue('buttonLink'),
                    showFilter: this.getValue('showFilter'),
                    showUnitType: this.parseBoolean(this.getValue('showUnitType')),
                    showBeds: this.parseBoolean(this.getValue('showBeds')),
                    showSqft: this.parseBoolean(this.getValue('showSqft')),
                    showAvailability: this.parseBoolean(this.getValue('showAvailability')),
                    showPrice: this.parseBoolean(this.getValue('showPrice')),
                    showDescription: this.parseBoolean(this.getValue('showDescription')),
                    callsToAction: this.parseBoolean(this.getValue('callsToAction')),
                });
            }
        }
    }
</script>

<style scoped>
    .features > div{
        display:flex;
        align-items:center;
    }
    .features > div + div{
        margin-top: 1rem;
    }
    .features label {
        display: block;
        font-weight: 200;
        text-transform: lowercase;
        margin-left: .5rem;
        margin-bottom: 0;
        line-height: 1;
    }
</style>
